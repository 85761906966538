// web_tUsfrequenttraveller_listForOrder 常旅客列表

const __request = require(`./__request/__request_contentType_form`);

// 接口文档地址： http://tour-beta.yinzhilv.com/doc.html#/yinzhilv_travel/t-us-frequent-traveller-controller/listForOrderUsingPOST
export default function web_tUsfrequenttraveller_listForOrder(pParameter) {
    if (!pParameter) pParameter = {};
    let params = {};
    params.method = 'POST';
    params.urlSuffix = '/web/tUsfrequenttraveller/listForOrder';
    params.data = pParameter;
    return new Promise(function (resolve, reject) {
        resolve(__request(params))
    })
}
