// buyer_dom_white_bookingContactInfo buyer_dom_white_bookingContactInfo
const __request = require(`./__request/__request_contentType_json`);

// 改期航班查询(新版)
// 接口文档地址：http://192.168.0.33:8985/doc.html#/yinzhilv-flight/buyer-dom-white-controller/bookingContactInfoUsingPOST
const buyer_dom_white_bookingContactInfo = (pParameter) => {
    if (!pParameter) pParameter = {};
    pParameter = {
        method: 'post',
        urlSuffix: '/buyer/dom/white/bookingContactInfo',
        data: pParameter
    }

    return __request(pParameter)
}
module.exports = buyer_dom_white_bookingContactInfo;