import web_tUsfrequenttraveller_listForOrder from "@/lib/data-service/default/web_tUsfrequenttraveller_listForOrder";
import buyer_dom_white_bookingContactInfo from "@/lib/data-service/flight/buyer_dom_white_bookingContactInfo";
import web_tUsfrequenttraveller_saveOrUpdate from "@/lib/data-service/default/web_tUsfrequenttraveller_saveOrUpdate";
import buyer_dom_white_ticketVoucherTypeEnumList from '@/lib/data-service/flight/buyer_dom_white_ticketVoucherTypeEnumList'
import buyer_dom_white_detailPolicy from '@/lib/data-service/flight/buyer_dom_white_detailPolicy'
const reserve = require(`@/lib/data-service/flight/flight_domestic_buyer_white_order_reserve`);
// const buyer_dom_white_detail = require(`@/lib/data-service/flight/buyer_dom_white_detail`);
import verification from "@/lib/deprecated/utils/verification/1.0.0";
import airStop from "@/page/distributor/air-ticket/components/airStop.vue";
import ChangClause from '@/page/distributor/air-ticket/components/changClause.vue';
import LuggageClause from '@/page/distributor/air-ticket/components/luggageClause/index.vue';
import NationalitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/nationality-selector/1.0.0/index.vue';
import {Loading} from "element-ui";
import moment from "moment/moment";
import {ProcurementRuleText} from "@/page/distributor/air-ticket/ProcurementRule.js";
import debounce from "@/lib/deprecated/utils/debounce";
import priceDetail from "@/lib/data-service/flight/buyer_dom_manage_order_price_detail";
import buyer_dom_white_policyProductTypeEnumList
    from "@/lib/data-service/flight/buyer_dom_white_policyProductTypeEnumList";
import buyer_dom_white_rule from "@/lib/common-service/buyer_dom_white_rule";
import buyer_dom_white_luggage from "@/lib/common-service/buyer_dom_white_luggage";
import _ from 'underscore';

export default {
    data() {
        const v_model_idCard = (rule, value, callback) => {
            let reg = /(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
            if (reg.test(value) === false) {
                callback(new Error("请输入正确身份证号码"));
                return;
            }
            let birthday = '';
            if (value.length === 15) {
                birthday = "19" + value.substr(6, 6);
            } else if (value.length === 18) {
                birthday = value.substr(6, 8);
            }
            birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-")
            let birthdayArr = birthday.split('-')
            let yearSubmit = parseInt(birthdayArr[0]) !== NaN && parseInt(birthdayArr[0]) >= 1900
            let monthSubmit = parseInt(birthdayArr[1]) !== NaN && parseInt(birthdayArr[1]) >= 1 && parseInt(birthdayArr[1]) <= 12
            let daySubmit = parseInt(birthdayArr[2]) !== NaN && parseInt(birthdayArr[2]) >= 1 && parseInt(birthdayArr[2]) <= 31
            if (!yearSubmit || !monthSubmit || !daySubmit) {
                callback(new Error("请输入正确身份证号码"));
            }
        };
        const v_model_name = (rule, value, callback) => {
            let reg = /^[0-9]+.?[0-9]*$/;
            let pinyin = /^[A-Za-z]+$/;
            let Chinese = /^[\u4e00-\u9fa5]+$/;
            if (!value) {
                callback(new Error("请输入乘机人姓名"));
            } else if (reg.test(value)) {
                callback(new Error("输入姓名有误！"));
            } else if (Chinese.test(value) && value.length > 12) {
                callback(new Error("中文名字最大输入12位"));
            } else if (pinyin.test(value)) {
                if (value.indexOf("/") === -1) {
                    callback(new Error("英文名字加上'/'分隔"));
                }
            } else {
                callback(verification.verifySpace(value));
            }
        };
        const v_model_contact_name = (rule, value, callback) => {
            let reg = /^[0-9]+.?[0-9]*$/;
            let pinyin = /^[A-Za-z]+$/;
            let Chinese = /^[\u4e00-\u9fa5]+$/;
            if (!value) {
                callback(new Error("请输入联系人姓名"));
            } else if (reg.test(value)) {
                callback(new Error("输入姓名有误！"));
            } else if (Chinese.test(value) && value.length > 12) {
                callback(new Error("中文名字最大输入12位"));
            } else if (pinyin.test(value)) {
                if (value.indexOf("/") === -1) {
                    callback(new Error("英文名字加上'/'分隔"));
                }
            } else {
                callback(verification.verifySpace(value));
            }
        };
        const checkBirthday = (rule, value, callback) => {
            let socpAges = this.detailFlight.teenOldInfo ? this.detailFlight.teenOldInfo.socpAges : null
            if (socpAges && socpAges.length > 0) {
                // 判断年龄
                const nowTime = moment(value).valueOf()
                let canPassAge = false
                socpAges.forEach(value => {
                    let maxTime = moment(value.maxAgeBirthday || '1900-01-01').valueOf()
                    let minTime = moment(value.minAgeBirthday || '2099-12-31').valueOf()
                    if (minTime >= nowTime && maxTime <= nowTime) {
                        canPassAge = true
                    }
                })
                if (canPassAge) {
                    callback()
                } else {
                    callback(new Error('乘客年龄不符合要求'))
                }
            } else {
                callback()
            }
        };
        return {
            v_model_idCard_rules: v_model_idCard,

            loading: false,
            loadingText: "加载中...",
            arrowsShow: null,
            image_url_airline_blank_map: `https://lyn.oss-cn-shenzhen.aliyuncs.com/temp/airline-blank-map.jpg`,

            ordersData: [], // 航班信息

            checkedProductTypeAll: false,
            productTypeList: [],
            productTypeEnumList: [], // 枚举列表
            checkPolicyPriceAll: false,
            policyPriceList: [],
            policyPriceEnumList: [], // 枚举列表
            checkTicketVoucherType: false,
            ticketVoucherTypeList: [],
            ticketVoucherTypeEnumList: [], // 枚举列表

            lpLowestPrice: null, // 最低价
            ypLowestPrice: null, // 平台最低价
            gwLowestPrice: null, // 官网最低价

            oriPolicyList: [], // 原始政策列表
            policyList: [],
            pricePolicy:[], // 选中后的那一个政策

            policyRadio: '',
            policyUuid: '',
            policyMaxCount: 5,

            // 常旅客表格列表
            tableData: [],
            tableDataFilter: [], // 过滤的常旅客table
            // 选择常旅客查詢參數
            searchDate: { // 常旅客 search 参数
                name: '',
                idType: '',
                idCard: '',
            },
            pageData: { // 常旅客 分页 参数
                currentPage: 1,
                pageSize: 10,
                total: 0
            },

            adultPrice: 0, // 成人价格
            childPrice: 0, // 儿童价格
            adultParPrice: 0, // 成人票面价格
            childParPrice: 0, // 儿童票面价格

            // 乘客数量
            passengerVal: 0,
            minPassengerVal: 1,
            maxPassengerVal: 9,
            // 乘客类型
            psgTypeOption: [
                {value: 1, label: "成人", disabled: false},
                {value: 2, label: "儿童", disabled: false}
            ],

            // 联系人信息
            contact: {
                contactEmail: "",
                contactName: "",
                remark: "",
                contactPhone: ""
            },

            // 输入框验证规则
            rules: {
                v_model_userType: [ // 乘客类型
                    {required: true, message: "请选择乘客类型", trigger: ['blur', 'change']}
                ],
                v_model_idType: [ // 证件证件类型
                    {required: true, message: "请选择证件类型", trigger: ['blur', 'change']}
                ],
                v_model_idCard: [ // 证件号码
                    {validator: v_model_idCard, required: true, trigger: ['blur', 'change']}
                ],
                v_model_phone: [ // 手机号码
                    {validator: verification.checkPhone, required: true, trigger: ['blur', 'change']}
                ],
                v_model_name: [ // 姓名
                    {validator: v_model_name, required: true, trigger: ['blur', 'change']}
                ],
                v_name: [ // 姓名
                    {validator: v_model_name, required: true, trigger: ['blur', 'change']}
                ],
                v_model_birthday: [ // 出生日期
                    {required: true, message: "请选择生日日期", trigger: ['blur', 'change']},
                    {validator: checkBirthday, required: true, trigger: ['blur', 'change']}
                ],
                contactName: [
                    {validator: v_model_contact_name, required: true, trigger: ['blur', 'change']}
                ],
                contactPhone: [
                    {validator: verification.checkPhone, required: true, trigger: ['blur', 'change']}
                ],
                adultTicketNumber: [
                    {required: true, message: "请输入成人票号", trigger: ['blur'] }
                ],
                adultCabin: [
                    {required: true, message: "请输入成人舱位", trigger: ['blur']}
                ],
                countryCode: [
                    {required: true, message: "请选择国籍", trigger: ['change', 'blur']}
                ],
                issCountryCode: [
                    {required: true, message: "请选择发证国家", trigger: ['change', 'blur']}
                ],
                cardExpireDate: [
                    {required: true, message: "请选择证件有效期", trigger: ['change', 'blur']}
                ],
                sex: [
                    {required: true, message: "请选择性别", trigger: ['change', 'blur']}
                ],
            },
            // 旅客证件
            cardType: [
                /*{value: 1, name: "身份证"},
                {value: 2, name: "护照"},
                // {value: 2, name: ''},
                // {value: 3, name: '身份证'},
                {value: 4, name: "港澳通行证"},
                {value: 5, name: "台胞证"},
                {value: 6, name: "台湾通行证"},
                {value: 7, name: "回乡证"},
                {value: 8, name: "军官证"},
                {value: 9, name: "其他"}*/
            ],
            sexType: [
                {value: 1, name: "男"},
                {value: 2, name: "女"},
            ],

            isFirstGetDetail: true,

            row_list: [], // 选中乘客数据列表

            alertingShow: false,
            alertingText: {
                child: [
                    "1.年龄：2岁（含）-12岁（不含）可购买儿童票；",
                    "2.儿童需在成人的陪同下乘机；无陪儿童请拨打航空公司确认是否可以购买；",
                    "3.请确认乘机人姓名与证件上姓名相同，否则无法登机。证件类型不为身份证时，格式传为姓/名，例如：“张/三”",
                    "4.预定儿童时，需正确填写成人的信息，儿童和成人必须乘坐相同航班、日期，出发地和目的地。",
                    "5.儿童需要成人陪同。一名成人旅客（满18周岁）仅可携带两名儿童旅客，超过儿童需要直接至航空公司柜台申请购买无成人陪伴儿童客票。",
                    "6.乘机证件：身份证，户口本，护照。",
                    "7.如大人退改签，儿童必须一起操作退改签，否则航司拒绝登机，我司不承担责任"
                ],
                adult: [
                    "1、请确认乘机人姓名与证件上姓名相同，否则无法登机。英文名请在姓名之间加“/“如“zhou/ielun”。护照、回乡证、台胞证姓名必须为英文或拼音且用/分隔。",
                    "2、预定儿童时，需正确填写成人信息，儿童和成人必须乘坐相同航班、相同舱位等级。",
                    "3、儿童、婴儿须有成人陪同，一名成人旅客仅可携带两名儿童旅客，超过儿童需直接至航空公司柜台申请购买无成人陪伴儿童客票。",
                    "4、儿童需预定Y舱，系统会名字后自动添加CHD，只享受儿童返点。",
                    "5、订单生成：您下单生成订单后，不得随意变更订座记录信息，若有变更必须重新下订单。",
                    "6、根据航空公司规定，分销商应准确录入客户手机号，多个乘客多个号码，不可重复，如出现多个乘机人同个号码，系统无法提交出票，请悉知！",
                    "7、按航司要求，旅客的联系手机必须录入乘机人真实的手机号码，如非真实乘机人手机号码导致的航司罚款，需自行承担，请知晓！"
                ]
            },

            airDataInfo: {},

            multipleSelection: [],
            dialogTableVisible: false,

            scrollTop: null, // 解决滚动问题
            priceShow: true,
            priceInfo: {},
            priceList: {},

            detailFlight: {},

            // 是否同意胤之旅规则
            checked: false,

            ProcurementRuleShow: false,
            ProcurementRuleText: ProcurementRuleText,

            dialogSuccessVisible: false, // 申请价提交订单后弹窗
            newOrderNo: '', // 生成的订单号

            dialogErrorVisible: false,
            dialogError2Visible: false,
            errorText: '',
            returnSecond: 5,
            returnSetInterval: '',

            ruleText: [],
            ruleType: 0,
            ruleComment: [],
            prompts: [],
            clauseLoading: false,
            luggageLoading: false,
            luggage: "",
            ruleObj: {},
        }
    },
    components: {airStop, ChangClause, LuggageClause, NationalitySelector},
    created() {
        this.airDataInfo = JSON.parse(this.$route.query.airdata);
        // 初始化数据选择常旅客table数据
        this.getTableList();
    },
    mounted() {
        let params = JSON.parse(this.$route.query.airdata);
        this.psgTypeOption = [
            {
                value: 1,
                label: "成人",
                disabled: Number(params.psgType) === 2 ? true : false
            },
            {
                value: 2,
                label: "儿童",
                disabled: Number(params.psgType) === 1 ? true : false
            }
        ];
        this.loading = true;
        this.isFirstGetDetail = false;

        this.checkedProductTypeAll = false;
        params.segments.forEach((item) => {
          if (item.productTypeTxt === '平台优选' && this.productTypeList.indexOf(1) == -1) {
            this.productTypeList.push(1);
          }
          if (item.productTypeTxt === '低价推荐' && this.productTypeList.indexOf(2) == -1) {
            this.productTypeList.push(2);
          }
          if (item.productTypeTxt === '官网' && this.productTypeList.indexOf(3) == -1) {
            this.productTypeList.push(3);
          }
        });
        if (this.productTypeList.length == 0) {
          this.productTypeList = [1];
        }
        this.productTypeEnumList = []; // 枚举列表
        this.checkPolicyPriceAll = false;
        this.policyPriceList = [];
        this.policyPriceEnumList = []; // 枚举列表
        this.checkTicketVoucherType = false;
        this.ticketVoucherTypeList = [];
        this.ticketVoucherTypeEnumList = []; // 枚举列表
        this.lpLowestPrice = null;
        this.ypLowestPrice = null;
        this.gwLowestPrice = null;

        this.getTicketVoucherTypeEnumList();
        this.getPolicyProductTypeEnumList();

        buyer_dom_white_detailPolicy(params).then((res) => {
            this.loading = false;
            this.ordersData = res.detail.flights;
            let detail = res.detail
            let flightItem = detail.flights[0]
            this.cardType = detail.teenOldInfo ? detail.teenOldInfo.cardTypeLimit : []
            this.detailFlight = detail;
            this.lpLowestPrice = flightItem.lpLowestPrice;
            this.ypLowestPrice = flightItem.ypLowestPrice;
            this.gwLowestPrice = flightItem.gwLowestPrice;
            if(null != flightItem.policyFacePriceList
                && undefined != flightItem.policyFacePriceList
                && flightItem.policyFacePriceList.length>0){
                let policyPriceEnumList=flightItem.policyFacePriceList;

                policyPriceEnumList = _.uniq(policyPriceEnumList);

                policyPriceEnumList.forEach(item => {
                    this.policyPriceEnumList.push({
                        value: item
                    })
                });
            }
            // this.policyPriceEnumList = flightItem.policyFacePriceList;
            // if (flightItem.fdLowestPrice) {
            //     this.policyPriceEnumList.push({
            //         value: flightItem.fdLowestPrice
            //     })
            // }
            // if (flightItem.nfdLowestPrice) {
            //     this.policyPriceEnumList.push({
            //         value: flightItem.nfdLowestPrice
            //     })
            // }
            this.oriPolicyList = JSON.parse(JSON.stringify(res.detail.flights[0].policys))
            // this.policyRadio = this.oriPolicyList[0].policyId;
            this.filterPolicy()
            if (this.detailFlight.teenOldInfo) {
                this.passengerVal = this.detailFlight.teenOldInfo.minPasgNum || 1
                this.minPassengerVal = this.detailFlight.teenOldInfo.minPasgNum || 1
                this.maxPassengerVal = this.detailFlight.teenOldInfo.maxPasgNum || 9
            } else {
                this.passengerVal = 1;
            }
            window.addEventListener('scroll', this.handleScroll);  // 监听（绑定）滚轮滚动事件
        }, error => {
            // 报其他code就返回搜索页
            this.loading = false;
            this.errorText = error.data.msg;
            this.dialogErrorVisible = true;
            this.returnSecond = 3;
            this.returnSetInterval = setInterval(() => {
                this.returnSecond--;
                if (this.returnSecond <= 0) {
                    this.returnToSearch()
                }
            }, 1000)
        }).catch(() => {
            this.loading = false;
            this.$router.go(-1);
        });

        this.getContactUser()
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        passengerVal() {
            // 乘客数量改变计算 成人 儿童数量
            this.adultNub = this.row_list.filter(this.compAdultNub).length;
            this.childNub = this.row_list.filter(this.compChildNub).length;

            this.gitPrice(this.psgType); // 乘客数量改变请求计算价格

            let params = JSON.parse(this.$route.query.airdata);
            let passenger = {
                // 出生日期，格式：yyyy-MM-dd
                birthday: "",
                // 证件号码
                cardNo: "",
                // 证件类型，1-身份证 2-护照 4-港澳通行证 5-台胞证 6-台湾通行证 7-回乡证 8-军官证 9-其他
                cardType: 1,
                // 是否保存至常旅客，0-不保存 1-保存
                isSaveFrequent: 0,
                // 手机号
                mobile: "",
                // 名字
                psgNameCn: "",
                v_name: "",
                v_model_name: null,
                // 乘客类型，1-成人 2-儿童
                psgType: Number(params.psgType) === 2 ? 2 : 1,
                v_model_userType: Number(params.psgType) === 2 ? 2 : 1,
                v_model_idType: this.cardType[0] ? this.cardType[0].cardType : 1,
                gender: 1,

                sex: '',
                countryCode: 'CN',
                countryCodeObj: {
                    code: 'CN'
                },
                issCountryCode: 'CN',
                issCountryCodeObj: {
                    code: 'CN'
                },
                cardExpireDate: '',
            };
            let Obj = {
                adultPsgNo: "",
                adultTicketNumber: "",
                adultCabin: "",
                adultPnr: ""
            };
            let passengerAdultList = [];
            if (this.airDataInfo.psgType == 2) {
                passengerAdultList = [Obj];
                this.airDataInfo.flightType == 2 ? passengerAdultList.push(Obj) : "";
            }
            let arr = [];
            let length = this.row_list.length;
            if (this.passengerVal === length) {
                return;
            }
            if (this.passengerVal > length) {
                let nub = this.passengerVal - length;
                for (let i = 0; i < nub; i++) {
                    params.psgType == 2 ? (passenger.passengerAdultList = passengerAdultList) : "";
                    arr.push(passenger);
                }
                this.row_list = this.deepClone([...this.row_list, ...arr]);
            }

            if (this.passengerVal < length) {
                let nub = length - this.passengerVal;
                for (let i = 0; i < nub; i++) {
                    this.row_list.pop();
                }
            }
        },

        searchDate: { // 常旅客search
            handler(val) {
                this.tableFilter(val);
            },
            deep: true
        },

        row_list: {
            handler(val) {
                let Obj = {
                    adultPsgNo: "",
                    adultTicketNumber: "",
                    adultCabin: "",
                    adultPnr: ""
                };
                if (this.airDataInfo.psgType == 3) {
                    val.map(item => {
                        if (item.psgType == 2) {
                            let passengerAdultList = [Obj];
                            item.passengerAdultList = passengerAdultList;
                        } else {
                            if (item.passengerAdultList) {
                                delete item.passengerAdultList;
                            }
                        }
                    });
                }

                // 监听数据改变计算成人儿童数量
                this.adultNub = val.filter(this.compAdultNub).length;
                this.childNub = val.filter(this.compChildNub).length;
            },
            deep: true
        }
    },
    computed: {},
    filters: {
        trainStartFilter(val) {
            return moment(val).format('YYYY年MM月DD日')
        },
        getWeekFilter (date) { // 参数时间戳
            let week = moment(date).day()
            switch (week) {
                case 1:
                    return '周一'
                case 2:
                    return '周二'
                case 3:
                    return '周三'
                case 4:
                    return '周四'
                case 5:
                    return '周五'
                case 6:
                    return '周六'
                case 0:
                    return '周日'
            }
        },
    },
    methods: {
        getTicketVoucherTypeEnumList() {
            buyer_dom_white_policyProductTypeEnumList().then(res => {
                this.productTypeEnumList = res.res
            })
        },
        getPolicyProductTypeEnumList() {
            buyer_dom_white_ticketVoucherTypeEnumList().then(res => {
                this.ticketVoucherTypeEnumList = res.res
            })
        },
        changeProductTypeAll(val) {
            if (val) {
                this.productTypeList = []
            }
            this.filterPolicy()
            this.policyMaxCount = 10;
        },
        changeProductTypeList(val) {
            if (val.length > 0) {
                this.checkedProductTypeAll = false
            }
            this.filterPolicy()
            this.policyMaxCount = 10;
        },
        changePolicyPriceAll(val) {
            if (val) {
                this.policyPriceList = []
            }
            this.filterPolicy()
            this.policyMaxCount = 10;
        },
        changePolicyPriceList(val) {
            if (val.length > 0) {
                this.checkPolicyPriceAll = false
            }
            this.filterPolicy()
            this.policyMaxCount = 10;
        },
        changeTicketVoucherAll(val) {
            if (val) {
                this.ticketVoucherTypeList = []
            }
            this.filterPolicy()
            this.policyMaxCount = 10;
        },
        changeTicketVoucherList(val) {
            if (val.length > 0) {
                this.checkTicketVoucherType = false
            }
            this.filterPolicy()
            this.policyMaxCount = 10;
        },
        // 过滤政策
        filterPolicy() {
            let oriPolicyList = JSON.parse(JSON.stringify(this.oriPolicyList));

            // 过滤政策类型
            if (this.productTypeList.length > 0) {
                let policyList = []
                // 1:平台优选 2:低价推荐 3:官网
                let productTypeList = []
                if (this.productTypeList.indexOf(1) > -1) {
                    productTypeList.push('平台优选')
                }
                if (this.productTypeList.indexOf(2) > -1) {
                    productTypeList.push('低价推荐')
                }
                if (this.productTypeList.indexOf(3) > -1) {
                    productTypeList.push('官网')
                }
                oriPolicyList.forEach(value => {
                    if (productTypeList.indexOf(value.productTypeTxt) > -1) {
                        policyList.push(value)
                    }
                })
                oriPolicyList = policyList
            }
            // 过滤价格
            if (this.policyPriceList.length > 0) {
                let policyList = []
                this.oriPolicyList.forEach(value => {
                    if (this.policyPriceList.indexOf(value.priceData[0].price) > -1) {
                        policyList.push(value)
                    }
                })
                oriPolicyList = policyList
            }
            // 过滤票证类型
            if (this.ticketVoucherTypeList.length > 0) {
                let policyList = []
                this.oriPolicyList.forEach(value => {
                    if (this.ticketVoucherTypeList.indexOf(value.ticketVoucherType) > -1) {
                        policyList.push(value)
                    }
                })
                oriPolicyList = policyList
            }
            this.policyList = oriPolicyList
        },
        onChangePolicy(val) {
            this.getAirParams()
            if (val !== null && val !== "") {
                this.policyUuid = val.split('_')[1]
                 let pricePolicy = [];
                this.policyList.forEach(value => {
                    if(value.policyUuid === this.policyUuid){
                        // selectPolicy = value;
                        pricePolicy.push(value)
                    }
                })
                this.pricePolicy = pricePolicy;
            }
        },
        toChangePolicy(val) {
            this.policyRadio = val.policyId
        },

        //退改规则
        backRule(policys) {
            this.ruleText = [];
            this.luggage = '';
            //this.ruleObj = val;
            let data = {
                arrCityPortCode: this.ordersData[0].arrAirportCode,
                cabinCode: this.psgType == 2 ? policys.cabin.chdCabinCode : policys.cabin.cabinCode,
                depCityPortCode: this.ordersData[0].depAirportCode,
                depDate: this.ordersData[0].depDate,
                flightNo: this.ordersData[0].hasShare ? this.ordersData[0].sharedFlightNo : this.ordersData[0].flightNo,
                flightType: this.airType,
                psgType: this.psgType,
                supplierType: policys.supplierType || 1,
                policyId: policys.policyId,
                extData: policys.extData,
                businessExt: policys.businessExt,
            };
            this.getLuggage(data);
            this.clauseLoading = true
            buyer_dom_white_rule(data).then(res => {
                this.ruleType = res.ruleType
                let refComment = res.refRule.comment
                let chaComment = res.chaRule.comment
                let refRule = res.refRule.rules;
                let chaRule = res.chaRule.rules;
                if (refRule && chaRule) {
                    refRule.map(item => (item.type = "refRule"));
                    chaRule.map(item => (item.type = "chaRule"));
                    this.ruleText = [refRule, chaRule];
                }
                this.ruleComment = [refComment, chaComment]
                this.prompts = res.prompts || []
                // this.ruleText = res.chaRule.content;
            }).finally(() => {
                this.clauseLoading = false
            });
        },
        backLuggage(policys) {
            this.luggage = ''
            // this.ruleObj = val;
            let data = {
                arrCityPortCode: this.ordersData[0].arrAirportCode,
                cabinCode: this.psgType === 2 ? policys.cabin.chdCabinCode : policys.cabin.cabinCode,
                depCityPortCode: this.ordersData[0].depAirportCode,
                depDate: this.ordersData[0].depDate,
                flightNo: this.ordersData[0].hasShare ? this.ordersData[0].sharedFlightNo : this.ordersData[0].flightNo,
                flightType: this.airType,
                psgType: this.psgType,
                supplierType: policys.supplierType || 1,
                policyId: policys.policyId,
                extData: policys.extData,
                businessExt: policys.businessExt,
            };
            this.getLuggage(data);
        },
        getLuggage(data) {
            this.luggageLoading = true
            buyer_dom_white_luggage(data).then(res => {
                this.luggageLoading = false
                this.luggage = res.luggage;
            }, error => {
                this.luggageLoading = false
            }).catch(() => {
                this.luggageLoading = false
            });
        },

        tableFilter(val) { // table 模糊搜索
            this.tableDataFilter = this.tableData.filter(item => {
                let matchName = true; // 姓名 英文名
                let matchIdType = true; // 证件类型
                let matchIdCard = true; // 证件号

                if (val.name) {
                    const keys = val.name
                        .toUpperCase() // 转大写
                        .replace(' ', '') // 删掉空格
                        .split(''); // 切割成 单个字
                    matchName = keys.every(key => item.name.toUpperCase().includes(key)) || keys.every(key => item.enName.toUpperCase().includes(key));
                }
                if (val.idType) {
                    matchIdType = item.idType == val.idType;
                }
                if (val.idCard) {
                    const keys = val.idCard.replace(' ', ''); // 删掉空格
                    matchIdCard = item.idCard.includes(keys);
                }
                return matchName && matchIdType && matchIdCard;
            });

            this.pageData.currentPage = 1;
            this.pageData.pageSize = 10;
            this.pageData.total = this.tableDataFilter.length;
        },
        onReset(formName) { // 重置
            this.$refs[formName].resetFields();
            this.tableFilter(this.searchDate);
        },
        // 获取常旅客table数据
        getTableList() {
            const __this = this;
            //this.loading = true;
            web_tUsfrequenttraveller_listForOrder({type: 1}).then((res) => {
                let newArr = [];
                for(let item of res.tUsFrequentTravellerResults) {
                    // item.isCheckBox = true; //列表是否可选
                    if (item.userType === this.airDataInfo.psgType) {
                        newArr.push(item);
                    } else if (this.airDataInfo.psgType === 3) {
                        newArr.push(item);
                    }
                }
                __this.tableData = newArr;

                __this.pageData.total = this.tableData.length;
                __this.tableDataFilter = this.paging(this.pageData.pageSize, this.pageData.currentPage);
                // __this.loading = false
            }).catch(() => {
                //this.loading = false
            }, new Error(() => {
                //this.loading = false
            }));
        },
        // 本地分页的方法
        paging(size, current) {
            const tableList = JSON.parse(JSON.stringify(this.tableData));
            const tablePush = [];
            tableList.forEach((item, index) => {
                if (size * (current - 1) <= index && index <= size * current - 1) {
                    tablePush.push(item);
                }
            });
            return tablePush;
        },
        // 页数改变事件
        handleSizeChange(size) {
            this.pageData.pageSize = size;
            this.tableDataFilter = this.paging(this.pageData.pageSize, this.pageData.currentPage);
        },
        // 页码改变事件
        handleCurrentChange(current) {
            this.pageData.currentPage = current;
            this.tableDataFilter = this.paging(this.pageData.pageSize, this.pageData.currentPage);
        },
        handleScroll () {
            if (!this.$refs.priceBox) {
                return
            }
            const domTop = this.$refs.priceBox.getBoundingClientRect().top; // 价格框距离顶部距离
            if (domTop > 750) {
                this.priceShow = true
            } else {
                this.priceShow = false
            }
        },
        returnToSearch() {
            // this.detailFlight
            if (!this.dialogErrorVisible) {
                return
            }
            this.dialogErrorVisible = false;
            clearInterval(this.returnSetInterval);
            let params = {
                airType: this.airDataInfo.flightType, // 航程类型// 航程类型
                time1: moment(this.airDataInfo.segments[0].depDate).valueOf(), //  出发时间
                time2: this.airDataInfo.flightType === 2 ? moment(this.airDataInfo.segments[1].depDate).valueOf() : null, //  返回时间
                time2Show: this.airDataInfo.flightType === 1 ? true : false, // 返回时间是否可用
                psgType: this.airDataInfo.psgType, //  乘机人类型
                ClassType: 0, //舱位等级
                airlines: '', //航空公司
                starCity: this.airDataInfo.segments[0].depAirportCode,
                starCity1: `${this.airDataInfo.segments[0].depCityName}(${this.airDataInfo.segments[0].depAirportCode})`,
                endCity: this.airDataInfo.flightType === 2 ? this.airDataInfo.segments[1].depAirportCode : this.airDataInfo.segments[0].arrAirportCode,
                endCity1: this.airDataInfo.flightType === 2 ? `${this.airDataInfo.segments[1].depCityName}(${this.airDataInfo.segments[1].depAirportCode})` : `${this.airDataInfo.segments[0].arrCityName}(${this.airDataInfo.segments[0].arrAirportCode})`
            };
            this.$router.push({
                name: "air-list",
                // path: '/air-list',
                query: {
                    airdata: JSON.stringify(params)
                }
            });
        },
        getContactUser () {
            buyer_dom_white_bookingContactInfo().then(res => {
                this.contact.contactName = res.name
                this.contact.contactPhone = res.phone
            })
        },
        toggleTable() { // 打开常旅客弹窗
            this.dialogTableVisible = true;
            if (this.$refs.multipleTable) {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) { // 选择旅客
            const __this = this;
            __this.multipleSelection = val;
        },
        //点击行触发，选中或不选中复选框
        handleRowClick(row, column, event) {
            this.$refs.multipleTable.toggleRowSelection(row);
        },
        addPassenger() { // 添加旅客
            const __this = this;
            const multipleSelection = __this.multipleSelection.length; // 选中的乘客个数
            let selectNum = 0; // 已存在的乘客个数
            for (let item of __this.row_list) {
                if (item.v_name && item.v_name !== '') {
                    selectNum = selectNum + 1;
                }
            }
            if (multipleSelection <= 0) {
                __this.$message({type: "warning", message: "请选择常旅客！"});
                return false;
            }

            if (multipleSelection + selectNum > 9) {
                __this.$message({type: "warning", message: "乘客最多只能添加9个！"});
                return false;
            }

            let multipleArr = [];
            for(let sItem of __this.multipleSelection) { // 选中乘客的循环
                // 判断选中乘客是否符合
                let sex = ''
                if (sItem.idType === 1) {
                    if (sItem.idCard.length == 15) {
                        sex = parseInt(sItem.idCard.substr(14, 1), 10) % 2 === 1 ? 1 : 2;
                    } else if (sItem.idCard.length == 18) {
                        sex = parseInt(sItem.idCard.substr(16, 1), 10) % 2 === 1 ? 1 : 2;
                    }
                } else {
                    sex = sItem.sex
                }
                let _passenger = {
                    id: sItem.id,
                    ref_name: `aFlightFrequentTravelerSelector_name` + sItem.id,
                    v_model_name: sItem,
                    v_name: sItem.name,
                    v_model_birthday: sItem.birthday,
                    v_model_idCard: sItem.idCard,
                    v_model_idType: sItem.idType,
                    v_model_phone: sItem.phone,
                    v_model_userType: sItem.userType,
                    isChecked: false,

                    sex: sex,
                    countryCode: 'CN',
                    countryCodeObj: {
                        code: 'CN'
                    },
                    issCountryCode: 'CN',
                    issCountryCodeObj: {
                        code: 'CN'
                    },
                    cardExpireDate: '',
                };

                const obj = {
                    adultPsgNo: "",
                    adultTicketNumber: "",
                    adultCabin: "",
                    adultPnr: ""
                };
                let passengerAdultList = [];
                if (__this.airDataInfo.psgType == 2) {
                    passengerAdultList = [obj];
                    __this.airDataInfo.flightType == 2 ? passengerAdultList.push(obj) : "";
                }
                __this.airDataInfo.psgType == 2 ? (_passenger.passengerAdultList = passengerAdultList) : "";
                multipleArr.push(_passenger);
            }

            for (let i = 0, j = 0, flag = true, len = __this.row_list.length; i < len; flag ? i++ : i) {
                if (j >= multipleSelection) break;
                if (__this.row_list[i] && __this.row_list[i].v_name === '') { // 如果没输入就直接移除该form
                    __this.row_list.splice(i, 1);
                    j++;
                    flag = false;
                } else {
                    flag = true;
                }
            }
            __this.row_list = this.deepClone([...multipleArr, ...__this.row_list]);

            __this.passengerVal = __this.row_list.length;
            __this.dialogTableVisible = false; // 关闭选择常旅客弹窗
        },
        v_name(rule, value, callback, index) {
            let userInfo = this.row_list[index]
            let reg = /^[0-9]+.?[0-9]*$/;
            let pinyin = /^[A-Za-z\/]+$/;
            let Chinese = /^[\u4e00-\u9fa5]+$/;
            if (!value) {
                callback(new Error("请输入乘机人姓名"));
            } else if (reg.test(value)) {
                callback(new Error("输入姓名有误！"));
            } else if (userInfo.v_model_idType === 2 || userInfo.v_model_idType === 7 || userInfo.v_model_idType === 5) {
                // 护照， 只能写英文
                if (pinyin.test(value)) {
                    if (value.indexOf("/") === -1) {
                        callback(new Error("英文名字加上'/'分隔"));
                    } else {
                        callback(verification.verifySpace(value));
                    }
                } else {
                    callback(new Error("请输入英文名字"));
                }
            }
            else {
                // 其他，按照原来的
                if (Chinese.test(value) && value.length > 12) {
                    callback(new Error("中文名字最大输入12位"));
                } else if (pinyin.test(value)) {
                    if (value.indexOf("/") === -1) {
                        callback(new Error("英文名字加上'/'分隔"));
                    } else {
                        callback(verification.verifySpace(value));
                    }
                } else {
                    callback(verification.verifySpace(value));
                }
            }
        },
        v_model_idCard(rule, value, callback, item) {
            if (item.v_model_idType === 1) {
                let reg = /(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                if (reg.test(value) === false) {
                    callback(new Error("请输入正确身份证号码"));
                    return;
                }
                let birthday = '';
                if (value.length === 15) {
                    birthday = "19" + value.substr(6, 6);
                } else if (value.length === 18) {
                    birthday = value.substr(6, 8);
                }
                birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-")
                let birthdayArr = birthday.split('-')
                let yearSubmit = parseInt(birthdayArr[0]) !== NaN && parseInt(birthdayArr[0]) >= 1900
                let monthSubmit = parseInt(birthdayArr[1]) !== NaN && parseInt(birthdayArr[1]) >= 1 && parseInt(birthdayArr[1]) <= 12
                let daySubmit = parseInt(birthdayArr[2]) !== NaN && parseInt(birthdayArr[2]) >= 1 && parseInt(birthdayArr[2]) <= 31
                if (!yearSubmit || !monthSubmit || !daySubmit) {
                    callback(new Error("请输入正确身份证号码"));
                }
            } else {
                callback()
            }
        },
        setCardType(item, index, val) {
            if (val !== 1) {
                this.rules.v_model_idCard[0] = {required: true, message: '请输入证件号码', trigger: ['blur', 'change']}
            } else {
                this.rules.v_model_idCard[0] = {validator: this.v_model_idCard_rules, required: true, trigger: ['blur', 'change']}
            }
            this.$nextTick(() => {
                this.$refs[`userForm${index}`][0].validateField('v_model_idCard')
            })
        },
        // 通过身份证获取生日
        fromIdCard(index, item) {
            if (item.v_model_idType !==1) {
                return
            }
            const __this = this;
            // let rowList = this.row_list;
            let idCard = item.v_model_idCard ? item.v_model_idCard : '';
            let birthday = '';
            let sex = ''
            if (idCard != null && idCard != "" && this.checkIdCard(idCard)) {
                if (idCard.length == 15) {
                    birthday = "19" + idCard.substr(6, 6);
                    // 获取性别
                    sex = parseInt(idCard.substr(14, 1), 10) % 2 === 1 ? 1 : 2;
                } else if (idCard.length == 18) {
                    birthday = idCard.substr(6, 8);
                    // 获取性别
                    sex = parseInt(idCard.substr(16, 1), 10) % 2 === 1 ? 1 : 2;
                }
                birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");

                item.v_model_birthday = birthday;
                item.sex = sex;
            }
        },
        checkIdCard(value) {
            let reg = /(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
            if (reg.test(value) === false) {
                return false;
            }
            let birthday = '';
            if (value.length === 15) {
                birthday = "19" + value.substr(6, 6);
            } else if (value.length === 18) {
                birthday = value.substr(6, 8);
            }
            birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-")
            let birthdayArr = birthday.split('-')
            let yearSubmit = parseInt(birthdayArr[0]) !== NaN && parseInt(birthdayArr[0]) >= 1900
            let monthSubmit = parseInt(birthdayArr[1]) !== NaN && parseInt(birthdayArr[1]) >= 1 && parseInt(birthdayArr[1]) <= 12
            let daySubmit = parseInt(birthdayArr[2]) !== NaN && parseInt(birthdayArr[2]) >= 1 && parseInt(birthdayArr[2]) <= 31
            if (!yearSubmit || !monthSubmit || !daySubmit) {
                return false
            } else {
                return true
            }
        },
        birthdayFormDisabledDate(val) {
            let socpAges = this.detailFlight.teenOldInfo ? this.detailFlight.teenOldInfo.socpAges : null
            if (socpAges && socpAges.length > 0) {
                const nowTime = moment(val).valueOf()
                let canPass = true
                socpAges.forEach(value => {
                    if (canPass) {
                        let maxTime = moment(value.maxAgeBirthday || '1900-01-01').valueOf()
                        let minTime = moment(value.minAgeBirthday || '2099-12-31').valueOf()
                        if (minTime >= nowTime && maxTime <= nowTime) {
                            canPass = false
                        }
                    }
                })
                return canPass
            } else {
                return false
            }
        },
        cardExpireDateDisabledDate(val) {
            const now = moment(val).format('YYYY-MM-DD')
            const nowTime = moment(now + ' 00:00:00').valueOf()
            const today = moment().format('YYYY-MM-DD')
            const todayTime = moment(today + ' 00:00:00').valueOf();
            return nowTime < todayTime
        },
        async querySearch(queryString, cb, list, index) {
            let res = await this.getAdultTicket(list, index);
            if (res.adultTicketInfos.length > 0) {
                res.adultTicketInfos.map(item => {
                    item.value = item.aduPsgName;
                });
            }
            const restaurants = res.adultTicketInfos;
            const results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        handleSelect(val, list) {
            list.adultCabin = val.aduCabin;
            list.adultPnr = val.aduPnr;
            list.adultPsgNo = val.aduPsgNo;
            list.adultTicketNumber = val.aduTicketNo;
            this.$forceUpdate();
        },
        adultTicketNumberVer(row, index, i) { // 手动移除成人票号验证信息
            const formName = 'adultTicketNumber' + index + i;
            if (row.adultTicketNumber) {
                this.$nextTick(() => {
                    // this.$refs[formName][0].resetField();
                    this.$refs[formName][0].clearValidate();
                })
            }
        },
        adultCabinVer(row, index, i) {
            const formName = 'adultCabin' + index + i;
            if (row.adultCabin) {
                this.$nextTick(() => {
                    // this.$refs[formName][0].resetField();
                    this.$refs[formName][0].clearValidate();
                })
            }
        },
        adultPnrVer(row, index, i) {
            const formName = 'adultPnr' + index + i;
            if (row.adultPnr) {
                this.$nextTick(() => {
                    // this.$refs[formName][0].resetField();
                    this.$refs[formName][0].clearValidate();
                })
            }
        },
        passengerMinus(formName, row, index) { // 点击移除乘机人 form
            const __this = this;
            // 移除后改变选中状态
            for(let item of __this.tableDataFilter) { // 改变状态
                if (item.id === row.id) {
                    __this.$refs.multipleTable.toggleRowSelection(item);
                }
            }
            if (__this.passengerVal <= __this.minPassengerVal) { // 判断只有一个乘客的时候重置表单
                __this.$nextTick(() => {
                    __this.row_list[index].id = 0;
                    __this.row_list[index].v_model_name = null;
                    if (__this.row_list[index].passengerAdultList) {
                        for (let adultItem of __this.row_list[index].passengerAdultList) {
                            adultItem.adultTicketNumber = '';
                            adultItem.adultCabin = '';
                            adultItem.adultPnr = '';
                        }
                    }
                    __this.$refs['userForm' + index][0].resetFields();
                    __this.gitPrice(this.psgType);
                });
                return;
            }
            __this.row_list.splice(index, 1);
            __this.passengerVal = __this.row_list.length;
        },
        passengerPlus() { // 点击添加乘机人 form
            const __this = this;
            if (__this.passengerVal >= __this.maxPassengerVal) {
                __this.$message({
                    type: "warning",
                    message: "最多只能添加9个乘客！"
                });
                return false;
            }
            const _passenger = {
                id: 0,
                ref_name: `aFlightFrequentTravelerSelector_name_0`,
                v_name: '',
                v_model_name: null,
                v_model_userType: Number(__this.airDataInfo.psgType) === 2 ? 2 : 1, // 用户类型 成人
                v_model_idType: __this.cardType[0].cardType, //  证件类型
                v_model_idCard: '', // 证件号码
                v_model_birthday: '', // 生日
                v_model_phone: '', // 电话号码
                isChecked: false,

                sex: '',
                countryCode: 'CN',
                countryCodeObj: {
                    code: 'CN'
                },
                issCountryCode: 'CN',
                issCountryCodeObj: {
                    code: 'CN'
                },
                cardExpireDate: '',
            };
            let Obj = {
                adultPsgNo: "",
                adultTicketNumber: "",
                adultCabin: "",
                adultPnr: ""
            };
            let passengerAdultList = [];
            if (__this.airDataInfo.psgType == 2) {
                passengerAdultList = [Obj];
                __this.airDataInfo.flightType == 2 ? passengerAdultList.push(Obj) : "";
            }
            let arr = [];
            __this.airDataInfo.psgType == 2 ? (_passenger.passengerAdultList = passengerAdultList) : "";
            arr.push(_passenger);
            __this.row_list = this.deepClone([...__this.row_list, ...arr]);
            __this.passengerVal = __this.row_list.length;
        },
        submit() {
            if (!this.checked) {
                this.$message({
                    type: "warning",
                    message: '请先阅读并同意《胤之旅操作规范-机票编 V2.0版》',
                    offset: 80,
                })
                return;
            }
            if (!this.policyRadio) {
                this.$message({
                    type: "warning",
                    message: '请先勾选产品',
                    offset: 80,
                })
                return;
            }
            const __this = this;

            for (let i = 0; i < __this.row_list.length; i++) {
                if (!this.verifyFrom(`userForm${i}`)) return;
                if(__this.row_list[i].passengerAdultList) {
                    for (let j = 0; j < __this.row_list[i].passengerAdultList.length; j++) {
                        __this.adultTicketNumberVer(__this.row_list[i].passengerAdultList[j], i, j); // 手动移除一下成人票号验证
                        __this.adultCabinVer(__this.row_list[i].passengerAdultList[j], i, j); // 手动移除一下成人仓位验证
                    }
                }

            }
            if (!this.verifyFrom("ruleForm")) return;
            let passengerArr = [];
            for (let item of __this.row_list) {
                const passengerObj = {
                    //gender: 1, // 性别
                    birthday: item.v_model_birthday, // 出生日期，格式：yyyy-MM-dd
                    cardNo: item.v_model_idCard, // 证件号码
                    idCard: item.v_model_idCard, // 证件号码
                    cardType: item.v_model_idType, // 证件类型，1-身份证 2-护照 4-港澳通行证 5-台胞证 6-台湾通行证 7-回乡证 8-军官证 9-其他
                    idType: item.v_model_idType, // 证件类型，1-身份证 2-护照 4-港澳通行证 5-台胞证 6-台湾通行证 7-回乡证 8-军官证 9-其他
                    isSaveFrequent: item.isChecked ? 1 : 0,  // 是否保存至常旅客，0-不保存 1-保存
                    isCheck: item.isChecked ? 1 : 0,  // 是否保存至常旅客，0-不保存 1-保存
                    mobile: item.v_model_phone, // 手机号
                    phone: item.v_model_phone, // 手机号
                    psgNameCn: item.v_name, // 名字
                    name: item.v_name, // 名字
                    psgType: item.v_model_userType, // 乘客类型，1-成人 2-儿童
                    userType: item.v_model_userType, // 乘客类型，1-成人 2-儿童
                    passengerAdultList: item.passengerAdultList,
                    gender: item.sex,
                    countryCode: item.countryCode,
                    issCountryCode: item.issCountryCode,
                    cardExpireDate: item.cardExpireDate
                };
                passengerArr.push(passengerObj);
            }
            this.passenger = passengerArr;

            if (!this.checkout()) return;
            if (!this.verifyPassenger(this.passenger)) return;

            let data = JSON.parse(__this.$route.query.airdata);
            let passengerList = JSON.parse(JSON.stringify(__this.row_list));

            if (__this.airDataInfo.psgType == 2) {
                if (__this.airDataInfo.flightType == 1) {
                    __this.row_list.map(item => {
                        item.adultCabin = item.passengerAdultList[0].adultCabin;
                        item.adultPsgNo = item.passengerAdultList[0].adultPsgNo;
                        item.adultPnr = item.passengerAdultList[0].adultPnr;
                        item.adultTicketNumber = item.passengerAdultList[0].adultTicketNumber;
                    });
                } else {
                    let newList = JSON.parse(JSON.stringify(passengerList));
                    newList.map(item => {
                        item.adultCabin = item.passengerAdultList[0].adultCabin;
                        item.adultPsgNo = item.passengerAdultList[0].adultPsgNo;
                        item.adultTicketNumber = item.passengerAdultList[0].adultTicketNumber;
                    });
                    passengerList.map(item => {
                        item.adultCabin = item.passengerAdultList[1].adultCabin;
                        item.adultPsgNo = item.passengerAdultList[1].adultPsgNo;
                        item.adultTicketNumber = item.passengerAdultList[1].adultTicketNumber;
                    });
                    passengerList = [...newList, ...passengerList];
                }
            }
            let params = {
                contactEmail: this.contact.contactEmail,
                contactName: this.contact.contactName,
                contactPhone: this.contact.contactPhone,
                contactQQ: "",
                passengers: this.passenger,
                isOpenUp: this.priceInfo.isOpenUp,
                remark: this.contact.remark,
                fullDecrement: this.priceInfo.fullDecrement,
                preferentialExplain: this.priceInfo.preferentialExplainCopy,
                sequenceKey: this.detailFlight.sequenceKey,
                needPayAmount: this.priceInfo.orderPayMoney,
                fullReductionId: this.priceInfo.fullReductionId,
                policyUuid: this.policyUuid,
            };

            let loadingInstance = Loading.service({
                text: '正在确认舱位和价格，请稍后…'
            });
            reserve(params)
                .then(
                    res => {
                        let data = res.orderNo;
                        this.newOrderNo = data;
                        loadingInstance.close();
                        if (this.detailFlight.flightType === 1) {
                            // 单程航班
                            if (this.ordersData[0].policys[0].policyType === '9999') {
                                // 申请价
                                this.dialogSuccessVisible = true;
                                return
                            }
                        }

                        this.$router.push({
                            name: "air-pay",
                            query: {
                                ordersNo: data
                            }
                        }).catch(err => {
                            this.loading = false;
                            this.$notify.error({
                                title: "错误",
                                message: "系统报错"
                            });
                        });
                    },
                    error => {
                        // this.loading = false;
                        loadingInstance.close();
                        /*根据code返回不一样的*/

                        let errorCode = error.data.code;
                        if (errorCode === '000102') {
                            this.errorText = '航班价格变动，请重新搜索航班';
                            this.dialogErrorVisible = true;
                            this.returnSecond = 5;
                            this.returnSetInterval = setInterval(() => {
                                this.returnSecond--;
                                if (this.returnSecond <= 0) {
                                    this.returnToSearch()
                                }
                            }, 1000)
                        } else if (errorCode === '000103') {
                            this.errorText = '座位不足，请重新搜索航班';
                            this.dialogErrorVisible = true;
                            this.returnSecond = 5;
                            this.returnSetInterval = setInterval(() => {
                                this.returnSecond--;
                                if (this.returnSecond <= 0) {
                                    this.returnToSearch()
                                }
                            }, 1000)
                        } else if (errorCode === '000105') {
                            this.errorText = '占位失败，请重新预定';
                            this.dialogErrorVisible = true;
                            this.returnSecond = 5;
                            this.returnSetInterval = setInterval(() => {
                                this.returnSecond--;
                                if (this.returnSecond <= 0) {
                                    this.returnToSearch()
                                }
                            }, 1000)
                        } else if (errorCode === '090104' || errorCode === '090113') {
                            this.errorText = '预订失败，请重新查询';
                            this.dialogErrorVisible = true;
                            this.returnSecond = 5;
                            this.returnSetInterval = setInterval(() => {
                                this.returnSecond--;
                                if (this.returnSecond <= 0) {
                                    this.returnToSearch()
                                }
                            }, 1000)
                        } else if (errorCode === '000101') {
                            this.errorText = error.data.msg;
                            this.dialogError2Visible = true
                        } else {
                            this.errorText = error.data.msg;
                            this.dialogError2Visible = true
                        }
                    }
                )
                .catch(err => {
                    loadingInstance.close();
                    this.$router.go(-1);
                });

            if (this.passenger.length > 0) {
                web_tUsfrequenttraveller_saveOrUpdate(this.passenger).catch();  // 下单后保存常旅客
            }
        },
        // 提交表单
        checkout() {
            if (this.airDataInfo.psgType == 2) {
                for (let item of this.passenger) {
                    if (!item.psgNameCn || !item.birthday || !item.mobile || !item.cardNo) {
                        this.$message({message: "请填写乘机人信息", type: "error"});
                        return false;
                    }
                    for (let adultItem of item.passengerAdultList) {
                        if (!adultItem.adultTicketNumber || !adultItem.adultCabin) {
                            return this.$message({message: "请填写乘机人信息", type: "error"});
                        }
                    }
                }
            } else {
                for (let item of this.passenger) {
                    if (item.psgType == 2) {
                        let _s = this.passenger.every(val => {
                            if (val.psgType == 1)  return false;
                            return true;
                        });
                        if (_s) {
                            this.$message({message: "请选择成人", type: "warning"});
                            return false;
                        }
                    }
                }

                for (let item of this.passenger) {
                    if (!item.psgNameCn || !item.birthday || !item.mobile || !item.cardNo) {
                        this.$message({
                            message: "请填写乘机人信息",
                            type: "error"
                        });
                        return false;
                    }
                }
            }
            return true;
        },
        verifyFrom(formName) {
            let statu = true;
            let form = this.$refs[formName];
            form = Array.isArray(form) ? form[0] : form;
            form.validate(valid => {
                if (!valid) {
                    statu = false;
                }
            });
            return statu;
        },
        //验证手机号
        verifyPassenger(passenger) {
            let list = Object.values(passenger);
            for (let _i = 0; _i < list.length - 1; _i++) {
                for (let _j = _i + 1; _j < list.length; _j++) {
                    if (list[_i].psgType === 1 && list[_j].psgType === 1) {
                        if (list[_i].mobile === list[_j].mobile) {
                            this.$message({
                                type: "warning",
                                message: "乘机人手机号码不能重复！"
                            });
                            return false;
                        }
                    }
                }
            }
            return true;
        },
        goRule() {
            // this.ProcurementRuleShow = true;
            const url = 'https://yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/2021/06/16/%E3%80%8A%E8%83%A4%E4%B9%8B%E6%97%85%E6%93%8D%E4%BD%9C%E8%A7%84%E8%8C%83-%E6%9C%BA%E7%A5%A8%E7%BC%96%20V2.0%E7%89%88%E3%80%8B.pdf'
            window.open(url)
        },
        goRead(item) {
            window.open(item.fileUrl)
        },
        // 继续下单
        continueToOrder() {
            let params = {
                airType: this.detailFlight.flightType, // 航程类型// 航程类型
                time1: moment(this.ordersData[0].depDate).valueOf(), //  出发时间
                time2: this.detailFlight.flightType === 2 ? moment(this.ordersData[1].depDate).valueOf() : null, //  返回时间
                time2Show: this.detailFlight.flightType === 1 ? true : false, // 返回时间是否可用
                psgType: this.airDataInfo.psgType, //  乘机人类型
                ClassType: 0, //舱位等级
                airlines: '', //航空公司
                starCity: this.ordersData[0].depAirportCode,
                starCity1: `${this.ordersData[0].depCityName}(${this.ordersData[0].depAirportCode})`,
                endCity: this.detailFlight.flightType === 2 ? this.ordersData[1].depAirportCode : this.ordersData[0].arrAirportCode,
                endCity1: this.detailFlight.flightType === 2 ? `${this.ordersData[1].depCityName}(${this.ordersData[1].depAirportCode})` : `${this.ordersData[0].arrCityName}(${this.ordersData[0].arrAirportCode})`
            };
            this.dialogSuccessVisible = false;
            // 去搜索页
            this.$router.push({
                name: 'air-list',
                query: {
                    airdata: JSON.stringify(params)
                }
            })
        },
        // 去订单详情
        goOrderDetail() {
            this.dialogSuccessVisible = false;
            this.$router.push({
                name: 'air-ticket-admin-airDetails',
                query: {
                    ordersNo: this.newOrderNo,
                    psgType: 1
                }
            })
        },
        // 底部文字部分
        gitPrice: debounce(function(type) { // debounce 添加防抖
            if (type === 1) {
                this.AllPrice = Math.ceil(((this.adultPrice + this.taxes) * this.adultNub).toFixed(2) * 100) / 100 + this.invoicePrice;
            }
            if (type === 2) {
                this.AllPrice = Math.ceil((this.childPrice * this.childNub).toFixed(2) * 100) / 100 + this.invoicePrice;
            }
            this.getAirParams(this.isFirstGetDetail)
        }),
        // 获取信息并计算价格
        async getAirParams(showLoading) {
            if (!this.policyRadio) {
                return
            }
            this.getPriceDetail();
            this.getTicket(this.ordersData).then(list => {
                this.calculate(list);
            });
        },
        getPriceDetail() {
            setTimeout(() => {
                let data = {
                    adultCount: this.adultNub,
                    childrenCount: this.childNub,
                    sequenceKey: this.detailFlight.sequenceKey,
                    policyId: this.policyRadio,
                };
                priceDetail(data).then(res => {
                    this.loading = false;
                    this.priceInfo = res.result;
                    if (this.priceInfo.priceDataVOList) {
                        this.priceInfo.priceDataVOList.forEach(res => {
                            if(res.crewType == 1) {
                                this.adultParPrice = res.rebatePrice
                            } else if (res.crewType == 2) {
                                this.childParPrice = res.rebatePrice
                            }
                        })
                    }
                    this.priceInfo.preferentialExplainCopy = this.priceInfo.preferentialExplain;
                    this.priceInfo.preferentialExplain = JSON.parse(
                        this.priceInfo.preferentialExplain
                    );
                }).catch(() => {
                    this.loading = false
                });
            }, 100);
        },
        // 深克隆
        deepClone(obj) {
            let _obj = JSON.stringify(obj);
            let objClone = JSON.parse(_obj);
            return objClone;
        },
        // 计算成人数量
        compAdultNub(data) {
            return data.v_model_userType === 1;
        },
        // 计算儿童数量
        compChildNub(data) {
            return data.v_model_userType === 2;
        },
        getTicket(val) {
            let adultList = [],
                childList = [];
            val.map((item, index) => {
                item.policys[0].priceData.map(val => {
                    val.crewType == 1 ? adultList.push(val) : childList.push(val);
                });
            });
            this.priceList = {adultList, childList};
            return new Promise(resolve => {
                resolve(this.priceList);
            });
        },
        //计算票价
        calculate(val) {
            let priceInfo = {
                orderTotal: 0, //订单总价
                settlement: 0, //结算价格
                rebate: 0, //返佣金额
                billingFee: this.detailFlight.billingFee, //开票费用
                taxes: 0 //税金
            };
            let _a = count(val.adultList, "price"); //成人票面价
            let _b = count(val.adultList, "rebatePrice"); //成人结算价
            let _c = count(val.childList, "price"); //儿童票面价
            let _d = count(val.childList, "rebatePrice"); //儿童结算价

            priceInfo.orderTotal =
                _a * this.adultNub + _c * this.childNub + priceInfo.billingFee;
            priceInfo.settlement =
                _b * this.adultNub + _d * this.childNub + priceInfo.billingFee;
            val.adultList.map(item => {
                priceInfo.taxes += Number(item.fuelTax + item.airportTax);
                priceInfo.rebate += Number(item.rebate);
            });

            function count(list, sumType) {
                let total = 0;
                if (list instanceof Array) {
                    list.forEach(item => {
                        total += item[sumType] + item.airportTax + item.fuelTax;
                    });
                    return total;
                }
            }
            this.priceInfo = priceInfo;
        },
        getDateWeek(val) {
            let week = new Date(val).getDay();
            let weekString = "";
            switch (week) {
                case 1:
                    weekString = "星期一";
                    break;
                case 2:
                    weekString = "星期二";
                    break;
                case 3:
                    weekString = "星期三";
                    break;
                case 4:
                    weekString = "星期四";
                    break;
                case 5:
                    weekString = "星期五";
                    break;
                case 6:
                    weekString = "星期六";
                    break;
                default:
                    weekString = "星期日";
                    break;
            }
            return weekString;
        },
        // 格式化数字
        numberToFixedTwo(num) {
            const str = String(num);
            if (!Number(str)) {
                return '0.00';
            } else {
                return Number(num).toFixed(2);
            }
        },
        changeNationlity(item, index, val) {
            item.countryCode = val.code
        },
        changeIssNationlity(item, index, val) {
            item.issCountryCode = val.code
        },
    }
}
