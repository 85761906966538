// web_tUsfrequenttraveller_saveOrUpdate 常旅客新增或修改，用于机票下单页面选择
const Q = require('q');
const __request = require(`./__request/__request_contentType_json`);

// 接口文档地址： http://tour-beta.yinzhilv.com/doc.html#/yinzhilv_travel/t-us-frequent-traveller-controller/saveOrUpdateUsingPOST_1
export default function web_tUsfrequenttraveller_saveOrUpdate(pParameter) {
    if (!pParameter) pParameter = {};


    // const params = {
    //     request: pParameter
    // };
    // console.log(params);
    // const p = {
    //     method: 'post',
    //     urlSuffix: '/web/tUsfrequenttraveller/saveOrUpdate',
    //     data: pParameter,
    //     // check_token: false
    // };
    // return new Promise(function(resolve, reject) {
    //     resolve(__request(p))
    // });

    // const params = {
    //     request: pParameter
    // };
    // console.log(params)
    return Q.when()
        .then(function () {
            return __request({
                method: 'post',
                urlSuffix: '/web/tUsfrequenttraveller/saveOrUpdate',
                data: pParameter,
                enable_error_alert: false
            });
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        });
}