// buyer_dom_white_detailPolicy
const __request = require(`./__request/__request_contentType_json`);
const buyer_dom_white_detailPolicy = (pParameter) => {
  // if (!pParameter) pParameter = {};
  // const data = {...pParameter}

  let data = {
    method: 'post',
    urlSuffix:'/buyer/dom/white/detailPolicy',
    data: pParameter,
    enable_error_alert: false // 临时隐藏
  };
  return __request(data)
}
module.exports = buyer_dom_white_detailPolicy;
