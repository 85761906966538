// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
// https://github.com/jashkenas/underscore/blob/1.6.0/underscore.js#L714
const debounce = (fn, interval = 500) => {
    let timer = null;
    return function f(...rest) {
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn.call(this, rest);
        }, interval);
    };
};
export default debounce;